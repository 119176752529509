<template>
  <section>
    <div class="all-devices ">
      <div class="d-flex justify-content-end mt-0 mb-1">
        <b-button
          id="show-btn"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          style="margin-top: -3rem"
          @click="openDevicesGroup"
        >
          Add Group
        </b-button>
        <AddDevicesGroup
          ref="add-devices-group"
          @getAllGroups="$refs.devicesGroups.getAllGroups()"
          @getAllDevicesUpdated="$refs.allDevicesUpdate.getAllDevices()"
        />
      </div>
      <b-card no-body>
        <b-tabs class="ml-2">
          <b-tab title="All Devices">
            <span>
              <AllDevices
                ref="allDevicesUpdate"
                @updateGroups="$refs.devicesGroups.getAllGroups()"
              />
            </span>
          </b-tab>
          <b-tab title="Groups">
            <span>
              <devices-group ref="devicesGroups" />
            </span>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </section>
</template>
<script>
import {
  BCard,
  BButton,
  BTabs,
  BTab,
  VBToggle,

} from 'bootstrap-vue'
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css'
import Vue from 'vue'
import Ripple from 'vue-ripple-directive'
import AddDevicesGroup from '@/components/devices-group/AddDevicesGroup.vue'
import DevicesGroup from '../components/devices-group/DevicesGroup.vue'
import AllDevices from '../components/devices-group/AllDevices.vue'

Vue.use(Vuesax)
Vue.directive('ripple', Ripple)

export default {
  components: {
    DevicesGroup,
    AddDevicesGroup,
    AllDevices,
    BCard,
    BButton,
    BTabs,
    BTab,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      spinner: false,
      allDevices: [],
    }
  },
  methods: {
    openDevicesGroup() {
      this.$refs['add-devices-group'].show()
    },
    fetchGroups() {
      this.$refs.devicesGroups.getAllGroups()
    },
  },
}
</script>
<style lang="scss" scoped>
.all-devices{
  .group-badge{
    color: #000000;
    cursor: default;
    font-size: 12px;
    margin: 5px;
  }
  .device-delete-icon{
    font-weight: 400;
    color: #6e6b7b;
    margin-left: 3px;
    cursor: pointer;
    transition: all 0.5s;
    &:hover{
      color: blue;
    }
  }
  .card:last-of-type{
    margin-bottom: 10px;
  }
}
.per-page-selector {
  width: 90px;
}

.vs__actions {
  font-weight: 100 !important;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
.switchs {
  width: 50px;
  padding-inline: 40px;
  border-radius: 25px;
  padding-top: 12px;
  padding-bottom: 12px;
  // background-color: #7FF0B2;
  color: white;
}
.devices{
  font-size: 14px;
  line-height: 11px;
  color: #1f58b5;
  font-weight: 500;
  font-family: "Montserrat";
}
</style>
