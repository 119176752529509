<template>
  <b-modal
    ref="edit-group-modal-1"
    centered
    hide-footer
    hide-header
    :no-close-on-backdrop="true"
    :no-close-on-esc="true"
    body-bg-variant="red"
  >
    <div class="edit-device-group d-flex justify-content-center align-content-center align-items-center">
      <b-row style="display: block ">
        <div class="closediv">
          <b-button
            variant="transparent"
            class="closebtn"
            @click="hide()"
          >
            <feather-icon
              icon="XIcon"
              class="cursor-pointer"
              size="16"
            />
          </b-button>
        </div>
        <div class="d-inline-block d-flex justify-content-center align-content-center align-items-center mt-1">
          <p class="font-weight-bolder heading1">
            Edit Devices Group
          </p>
        </div>
        <div class="d-inline-block m-1 d-flex justify-content-center align-content-center align-items-center">
          <div class="form">
            <validation-observer ref="editProfileForm">
              <b-form
                class="mt-2"
                @submit.prevent
              >
                <div>
                  <b-form-group
                    text-bolder
                    label="Group Name"
                    class="label"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="userDevice"
                      rules="required"
                    >
                      <b-form-input
                        id="input-1"
                        v-model="userDeviceGroupName"
                        placeholder="Enter Group Name"
                        class="input1"
                        trim
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="mb-2">
                  <b-form-group
                    text-bolder
                    label="Devices"
                    class="label"
                  >
                    <v-select
                      v-model="selectedDeviceForGroup"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      multiple
                      select-size="3"
                      class="search"
                      label="name"
                      :options="filteredDevices"
                      @search="onSearch"
                    />
                  </b-form-group>
                </div>
                <!-- submit buttons -->
                <div
                  class="form-group block mt-auto"
                >
                  <b-button
                    block
                    variant="primary"
                    type="submit"
                    class="button mt-1"
                    :disabled="!active"
                    @click="editDeviceGroupSave(id,$event)"
                  >
                    <div
                      v-if="spinner"
                      class="spinner"
                    >
                      <b-spinner
                        small
                      />
                      <span class="sr-only">Loading...</span>
                    </div>
                    Save
                  </b-button>
                </div>
                <div class="d-inline-block d-flex justify-content-center align-content-center align-items-center">
                  <b-link
                    variant="link"
                    class="link"
                    @click="hide()"
                  >
                    Delete
                  </b-link>
                </div>
              </b-form>
            </validation-observer>
          </div>
        </div>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import {
  BRow,
  BButton,
  BLink,
  BForm,
  BFormGroup,
  BFormInput,
  BSpinner,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BButton,
    BLink,
    BForm,
    BFormGroup,
    BFormInput,
    BSpinner,
    vSelect,
    // validations
    ValidationProvider,
    ValidationObserver,

  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['id', 'src', 'title', 'selectedDevices'],
  data() {
    return {
      userDeviceGroupName: this.title,
      active: true,
      spinner: false,
      selectedDeviceForGroup: [],
      selectedId: [],
      allDevices: [],
      search: '',
    }
  },
  computed: {
    filteredDevices() {
      return this.allDevices.filter(n => !this.selectedDeviceForGroup.map(device => device.id).includes(n.id))
    },
  },
  methods: {
    onSearch(query) {
      this.search = query
      this.getAllDevices()
    },
    show() {
      this.getAllDevices()
      this.selectedDeviceForGroup = Object.assign([], this.selectedDevices)
      this.userDeviceGroupName = this.title
      this.$refs['edit-group-modal-1'].show()
    },
    hide() {
      this.$refs['edit-group-modal-1'].hide()
      this.userDeviceGroupName = this.title
    },
    getAllDevices() {
      this.$axios.get(`user/device?offset=${0}&limit=${20}&search_query=${this.search}`)
        .then(({
          data: {
            data,
          },
        }) => {
          this.allDevices = data.results
        }).catch(() => this.$swal('Device not found!'))
    },
    editDeviceGroupSave(id) {
      this.$refs.editProfileForm.validate().then(async success => {
        if (success) {
          this.spinner = true
          this.active = false
          await this.editDeviceGroup(id)
          this.spinner = false
          this.active = true
          this.hide()
        }
      })
    },
    async editDeviceGroup(id) {
      this.selectedId = this.selectedDeviceForGroup.map(m => m.id)
      const data = {
        name: this.userDeviceGroupName,
        device_has_group: this.selectedId,
      }
      return this.$axios
        .put(`groups/${id}`, data)
        .then(async () => {
          await this.$emit('getAllGroups')
          await this.getAllDevices()
        }).catch(error => this.$swal(error))
    },
  },
}
</script>
<style lang="scss">
.edit-device-group {
  .search {
    .vs__dropdown-menu {
      max-height: 80px !important;
      overflow-y: auto !important;
      width: 320px !important;
    }
    .vs__dropdown-toggle{
      max-height: 60px !important;
      overflow-y: auto !important;
      width: 320px !important;
    }
  }
}
.label {
  font-size: 14px;
  color: #5e5873;
  font-weight: 600;
  font-family: "Montserrat";
}

.button {
  width: 300px;
  height: auto;
  border-radius: 5px !important;
}

.link {
  text-decoration: underline !important;
  font-size: 14px;
  line-height: 22px;
  text-decoration: underline;
  color: red;
  font-weight: 500;
  font-family: "Montserrat";
  text-align: center;
}

</style>
